import React, { useState } from "react";
import BookingLayout from "./BookingLayout";
import { navigate } from "gatsby";

export default function BookingIndex({ daa }) {
  const [loader, setLoader] = useState(false);

  return (
    <>
      {loader ? (
        <div className="loaderBod">
          <div className="loader2"></div>
        </div>
      ) : (
        <BookingLayout title="Choose Service" back="/">
          <div className="bookini">
            {daa.map((v, k) => (
              <div
                key={k}
                onClick={() => {
                  navigate("/booking/" + v.slug);
                  setLoader(true);
                }}
              >
                <img src={v.icon.localFile.publicURL} alt="" />
                <br />
                <p>{v.title}</p>
              </div>
            ))}
          </div>
          <div style={{ height: 100 }} />
        </BookingLayout>
      )}
    </>
  );
}
